.footer {
    /* position: fixed;
    bottom: 3%;
    left: 50%; */
    /* transform: translateX(-50%) translateX(var(--side-bar-half-width)); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 90;
}
/* 채팅 입력창과 전송 버튼을 감싸는 컨테이너 */
.chat_input_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary-variant);
    border-radius: 1rem;
    overflow: hidden;
    width: 50%;
}

/* 채팅 입력창 */
.chat_input {
    flex: 1;
    border: none;
    padding-left: 10px;
    font-size: 1rem;
    background-color: var(--color-primary-variant);
    outline: none;
    color: var(--color-text);
    resize: none; /* 크기 조절 비활성화 */
}
.chat_input::placeholder {
    text-align: left;
    line-height: 30px; /* 위에서 설정한 textarea 높이와 같게 설정 */
}
/* 채팅 전송 버튼 */
.chat_send_button {
    padding: 1rem;
    color: var(--color-text-secondary);
    border: none;
    border-radius: 4px;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
}

.description {
    font-size: small;
    margin-top: 3px;
    color: var(--color-text);
}
@media screen and (max-width: 768px) {
    .chat_input_container {
        width: 80%;
    }
}
