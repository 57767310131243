.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.icon {
    font-size: 20rem;
    color: var(--color-text);
    animation: spin 10s infinite linear;
}

.fixedText {
    color: var(--color-text);
    font-size: 3.5rem;
}

@keyframes slideDownFadeOut {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    50% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}

.text {
    opacity: 0;
    animation: slideDownFadeOut 3s linear infinite;
    color: var(--color-accent);
    font-size: 5rem;
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.endText {
    opacity: 0;
    animation: slideDown 3s linear forwards;
    color: var(--color-accent);
    font-size: 5rem;
}
@media screen and (max-width: 768px) {
    .icon {
        font-size: 10rem;
    }
    .fixedText {
        font-size: 2.5rem;
    }
    .text {
        font-size: 3rem;
    }
    .endText {
        font-size: 3rem;
    }
}
