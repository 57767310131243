.skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.title {
    margin-top: 8rem;
    color: var(--color-text);
    font-size: 3rem;
}

.container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 0;
    width: 80%;
}
@media screen and (max-width: 768px) {
    .title {
        margin-top: 3.5rem;
    }
}
