.header {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%) translateX(var(--side-bar-half-width));
    display: flex;
    background-color: var(--color-black);
    border-radius: 0.725rem;
    padding: 0rem 0.1rem;
    z-index: 10;
}

@media screen and (max-width: 768px) {
    .header {
        display: none;
    }
}
