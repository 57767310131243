.projects_container {
    display: fixed;

    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.title {
    margin-top: 10rem;
    font-size: 4rem;
}
.accent {
    color: var(--color-accent);
}
.categories {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2.5rem;
    gap: 1rem;
}

.category {
    position: relative;
    color: var(--color-text);
    font-size: 1.1rem;
    padding: 0.5rem 3rem;
    border-radius: 4px;
    border: 1px solid var(--color-accent);
    cursor: pointer;
    white-space: nowrap;
}

.category--selected {
    background-color: var(--color-accent);
    color: var(--color-primary);
}

.category__count {
    opacity: 0;
    position: absolute;
    top: -20px;
    right: 16px;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 100%;
    background-color: var(--color-accent-variant);
    color: var(--color-text);
    transition: all 250ms ease-in;
}

.category--selected .category__count,
.category:hover .category__count {
    opacity: 1;
    top: 0;
}

.projects {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 300px));
    grid-gap: 1rem;
    width: 80%;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .title {
        margin-top: 3.5rem;
        font-size: 3rem;
    }
    .categories {
        flex-direction: column;
    }
    .projects {
        display: flex;
        flex-direction: column;
    }
}
