.skill_card {
    width: 25rem;
    height: 20rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.skill_card span:nth-child(1) {
    position: absolute;
    border-top: 2px solid var(--color-accent);
    top: 0;
    left: 0;
    right: 100%;
    transition: 0.2s;
}
.skill_card:hover span:nth-child(1) {
    right: 0%;
}

.skill_card span:nth-child(2) {
    position: absolute;
    border-right: 2px solid var(--color-accent);
    top: 0;
    right: 0;
    bottom: 100%;
    transition: 0.2s;
}
.skill_card:hover span:nth-child(2) {
    bottom: 0%;
}

.skill_card span:nth-child(3) {
    position: absolute;
    border-bottom: 2px solid var(--color-accent);
    bottom: 0;
    left: 100%;
    right: 0;
    transition: 0.2s;
}
.skill_card:hover span:nth-child(3) {
    left: 0%;
}

.skill_card span:nth-child(4) {
    position: absolute;
    border-left: 2px solid var(--color-accent);
    top: 100%;
    left: 0;
    bottom: 0;
    transition: 0.2s;
}
.skill_card:hover span:nth-child(4) {
    top: 0%;
}

.icon {
    color: var(--color-accent);
    font-size: 3rem;
}

h3 {
    margin: 1rem;
    font-size: 2rem;
    color: var(--color-text);
}

.description {
    position: relative;
    padding: 0px 0px 2px 30px;
    line-height: 1.5;
    color: var(--color-text);
    font-size: 1rem;
    cursor: default;
}

.description::before {
    content: ">";
    position: absolute;
    left: 0.5rem;
    height: 0px;
    color: var(--color-accent);
}
