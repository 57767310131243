.container {
    display: flex;
    width: 100%;
    height: 100%;
}

.main {
    margin-left: var(--side-bar-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 768px) {
    .main {
        margin-left: 0;
    }
}
