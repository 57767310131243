.navigation {
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: var(--side-bar-width);
    height: 100%;
    background-color: var(--color-background);
    z-index: 100;
    transform: translateX(0);
    transition: transform 2s ease;
}
.hide__container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: var(--side-bar-width);
    z-index: 100;
    margin: 1rem;
}
.navigation.hide {
    transform: translateX(-100%);
}
.menue {
    position: relative;
    display: flex;
    align-items: center;
}

.newchat {
    display: flex;
    gap: 1rem;
    margin: 0.5rem 0.8rem;
    background-color: var(--color-background);
    color: var(--color-text);
    font-size: 1rem;

    border: 1px solid var(--color-border);
    border-radius: 10px;
    padding: 12px;
    width: 70%;
}
.newchat:hover {
    background-color: var(--color-cloud);
    border-radius: 0.375rem;
}

.hide_slider {
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 10px;
}

.hide_slider:hover {
    background-color: var(--color-cloud);
    border-radius: 0.375rem;
}

.tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 98;
    width: auto;
    left: 17rem;

    opacity: 0;
    transition: opacity 1s ease;

    /* clip: rect(0px, 0px, 0px, 0px); */
    white-space: nowrap;
    overflow-wrap: normal;
}

.hide_slider:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.tooltip::before {
    content: "";
    position: absolute;
    bottom: 0.3px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 12.5px solid transparent;
    border-bottom: 12.5px solid transparent;
    border-right: 12.5px solid black;
}

.container {
    display: flex;
    align-items: center;
    padding: 0.8rem 0.4rem;
    margin: 1rem 1.2rem;
    gap: 1rem;
}
.container:hover {
    background-color: var(--color-cloud);
    border-radius: 0.375rem;
}
.container.active {
    background-color: var(--light-gray);
    border-radius: 0.375rem;
    color: var(--color-accent);
}
.icon {
    font-size: 1.2rem;
    color: var(--color-text);
}

.title {
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--color-text);
}
.title.active {
    color: var(--color-accent);
}
.icon_contain.active {
    color: var(--color-accent) !important;
}
.about {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--color-text);
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.button {
    display: flex;
    align-items: center;
    width: 50%;
    gap: 10px;
    background-color: var(--color-background);
}
.button:hover {
    background-color: var(--color-cloud);
    border-radius: 0.375rem;
}

.items {
    display: flex;
    gap: 1rem;
}
