.link {
    color: inherit;
    text-decoration: none;
    width: 150px;
    padding: 0.3rem;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.725rem;
    padding: 1rem 0;
    gap: 10px;
}
.item:hover {
    background-color: var(--color-primary);
}
.icon {
    font-size: 0.8rem;
    color: var(--color-text-secondary);
    transition: all 300ms ease;
}

.title {
    color: var(--color-text-secondary);
    transition: all 300ms ease;
}
.title.active {
    color: var(--color-accent);
}
.icon.active {
    transform: rotate(-30deg);
}
