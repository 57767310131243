.dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
}
.dialog_title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.icon {
    font-size: 8rem;
    transform: rotate(30deg);
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;
    height: 65%;
    background-color: var(--color-background);
    color: var(--color-text);
    padding: 20px;
    border-radius: 1rem;
}

.form_container {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
}

.text_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
}
.text {
    font-size: 1rem;
    border-radius: 1rem;
}
.input_text {
    font-size: 0.8rem;
    color: var(--color-text);
    height: 2rem;
    width: 80%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--color-text);
    margin-bottom: 2rem;
}
.email {
    font-size: 1rem;
    height: 2.5rem;
    width: 50%;
    border-radius: 1rem;
}

.message_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    align-items: center;
    gap: 1rem;
}

.message {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid var(--color-text);
    color: var(--color-text);
}

.button_container {
    display: flex;
    justify-content: center;
    gap: 2rem;

    width: 100%;
    height: 10%;
}

.send_text {
    color: var(--color-text);
    font-size: large;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    background-color: var(--color-accent);
    width: 100%;
    border-radius: 4px;
}
.close_text {
    color: var(--color-text);
    font-size: large;
    width: 100%;
    border-radius: 1rem;
}

.close_text:hover {
    background-color: var(--color-accent);
}
@media screen and (max-width: 768px) {
    .dialog_title {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    .icon {
        font-size: 8rem;
        transform: rotate(30deg);
    }
    .container {
        width: 100%;
    }
    .message_container {
        height: 100%;
    }
}
