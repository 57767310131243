.project {
    position: relative;
    width: 300px;
    height: 250px;
}

.project__img {
    width: 300px;
    height: 250px;
}

.project__metadata {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 150ms ease-in;
    transform: translateY(-10px);
}

.project__metadata:hover {
    opacity: 0.8;
    transform: translateY(0px);
}
.project__title {
    font-size: 1.2rem;
}

.project__title::after {
    content: "";
    display: block;
    position: relative;
    left: 50%;
    width: 50%;
    height: 2px;

    transform: translateX(-50%);
    margin: 0.5rem 0;
    background-color: var(--color-accent);
}
.task {
    font-size: 0.9rem;
}
.time {
    font-size: 0.8rem;
}
.from {
    font-style: italic;
}
.time::after {
    content: "";
    display: block;
    position: relative;
    left: 50%;
    width: 50%;
    height: 1px;
    transform: translateX(-50%);
    margin: 0.5rem 0;
    background-color: var(--color-accent);
}

.teck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    margin-top: 3px;
    justify-content: center;
}

.teck li {
    padding: 4px;
    margin: 0px 2px 5px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 2px;
    font-size: 0.8rem;
    line-height: 1;
    color: var(--color-text);
}
